import React from 'react';
const TermsPlain = (props) => {

    return (
        <div className={(props.noStyle) ? "" : "container termsContainer col-11 col-lg-8"}>           

            <h1>Termini e condizioni del servizio</h1>

            <h2>Panoramica</h2>
            <p>Questo sito web è gestito dal proprietario del dominio www.dodaja.it, d'ora in poi anche "Dodaja". I termini “noi” e “nostro” all'interno del sito si riferiscono a Dodaja. Dodaja ti offre questo sito web con tutte le informazioni, gli strumenti e i servizi in esso disponibili a condizione che tu, in qualità di utente, accetti tutti i termini, le condizioni, le informative e le avvertenze qui riportate.</p>

            <p>Visitando il nostro sito e/o acquistando qualcosa da noi, usufruisci del nostro “Servizio” e accetti di essere vincolato dai seguenti termini e condizioni (“Termini e condizioni del servizio”, “Termini”), che includono i termini, le condizioni e le informative aggiuntive citate nel presente documento e/o disponibili tramite collegamento ipertestuale. I presenti Termini e condizioni del servizio si applicano a tutti gli utenti del sito, compresi a titolo esemplificativo e non esaustivo visitatori, fornitori, clienti, commercianti e/o autori di commenti e altri contenuti.</p>

            <p>Leggi con attenzione questi Termini e condizioni del servizio prima di accedere o utilizzare il nostro sito web. Accedendo o utilizzando qualsiasi parte del sito, accetti di essere vincolato dai presenti Termini e condizioni del servizio. Se non accetti integralmente i termini e le condizioni del presente accordo, non puoi accedere al sito web né utilizzare i suoi servizi. Se i presenti Termini e condizioni del servizio vengono considerati una proposta, l'accettazione è espressamente limitata ai Termini stessi.</p>

            <p>Eventuali nuove funzionalità e strumenti aggiunti all'attuale sito saranno anch'essi soggetti ai Termini e condizioni del servizio. Puoi consultare la versione più recente dei Termini e condizioni del servizio in qualsiasi momento su questa pagina. Ci riserviamo il diritto di aggiornare, modificare o sostituire qualsiasi parte dei Termini e condizioni del servizio pubblicando aggiornamenti e/o modifiche sul nostro sito web. È tua responsabilità controllare periodicamente questa pagina per vedere se sono presenti delle modifiche. La prosecuzione dell'utilizzo o dell'accesso al sito web dopo la pubblicazione di eventuali modifiche equivale all'accettazione di tali modifiche.</p>

            <h2>Sezione 1 - Termini e condizioni del sito</h2>
            <p>Accettando i presenti Termini e condizioni del servizio, dichiari di avere almeno la maggiore età nel tuo stato o provincia di residenza, o che hai la maggiore età nel tuo stato o provincia di residenza e ci hai autorizzato a consentire a qualsiasi minore sotto la tua responsabilità di utilizzare questo sito.</p>

            <p>Non puoi utilizzare i nostri prodotti per scopi illegali o non autorizzati né puoi, nell'uso del Servizio, violare alcuna legge vigente nel tuo ordinamento (incluse a puro titolo esemplificativo le leggi sul copyright).</p>

            <p>Non ti è consentito diffondere worm, virus o altri tipi di codice dannoso.</p>

            <p>La violazione di una qualsiasi disposizione dei Termini comporterà l'immediata cessazione del tuo diritto a usare i Servizi.</p>

            <h2>Sezione 2 - Condizioni generali</h2>
            <p>Ci riserviamo il diritto di negare il servizio a chiunque, per qualsiasi motivo e in qualsiasi momento.</p>

            <p>Comprendi che i tuoi contenuti (ad eccezione dei dati della carta di credito) possono essere trasferiti in chiaro e implicano (a) trasmissioni su varie reti; e (b) modifiche per conformarsi e adattarsi ai requisiti tecnici delle reti o dei dispositivi di connessione. I dati delle carte di credito vengono sempre crittografati durante il trasferimento sulle reti.</p>

            <p>Accetti di non riprodurre, duplicare, copiare, vendere, rivendere o sfruttare alcuna parte del Servizio, né l'uso del Servizio, l'accesso al Servizio o qualsiasi contatto sul sito web attraverso il quale il servizio è fornito senza espressa autorizzazione scritta da parte nostra.</p>

            <p>I titoli utilizzati nel presente accordo sono inclusi solo per comodità e non limiteranno né influenzeranno in alcun modo i presenti Termini.</p>

            <h2>Sezione 3 - Precisione, completezza e tempestività delle informazioni</h2>

            <p>Non saremo responsabili nel caso in cui le informazioni rese disponibili su questo sito non dovessero risultare accurate, complete o aggiornate. I materiali su questo sito sono da intendersi come puramente indicativi e non dovranno essere presi a riferimento o utilizzati come unica base per prendere decisioni senza consultare fonti di informazione più autorevoli, accurate, complete o aggiornate. Facendo affidamento sulle informazioni di questo sito te ne assumi il rischio.</p>

            <p>Questo sito può contenere alcune informazioni di carattere storico. Le informazioni storiche, necessariamente, non sono attuali e sono fornite solo come riferimento. Ci riserviamo il diritto di modificare i contenuti di questo sito in qualsiasi momento, ma non siamo soggetti all'obbligo di aggiornare alcuna informazione sul nostro sito. Accetti che è tua responsabilità monitorare le modifiche al nostro sito.</p>

            <h2>Sezione 4 - Modifiche al servizio e ai prezzi</h2>
            <p>I prezzi dei nostri prodotti sono soggetti a modifiche senza preavviso.</p>

            <p>Ci riserviamo il diritto di modificare o interrompere il Servizio (o qualsiasi sua parte o contenuto) senza preavviso in qualsiasi momento.</p>

            <p>Non saremo responsabili nei confronti tuoi o di terze parti per eventuali modifiche, variazioni di prezzo, sospensioni o interruzioni del Servizio.</p>

            <h2>Sezione 5 - Prodotti o servizi (se applicabile)</h2>
            <p>Alcuni prodotti o servizi possono essere disponibili esclusivamente online tramite il sito web. Questi prodotti o servizi possono essere disponibili in quantità limitate, ed essere soggetti a reso o sostituzione solo in base alla nostra Informativa su rimborsi e resi.</p>

            <p>Abbiamo fatto ogni sforzo possibile per mostrare colori e immagini fedeli dei prodotti presenti nel sito. Tuttavia non possiamo garantire che i colori visualizzati sullo schermo del tuo computer siano accurati.</p>

            <p>Ci riserviamo il diritto (anche se non siamo obbligati a farlo) di limitare la vendita dei nostri prodotti o Servizi nei confronti di qualsiasi soggetto, area geografica o giurisdizione. Potremo esercitare questo diritto caso per caso. Ci riserviamo il diritto di limitare la quantità di prodotti o servizi che offriamo. Le descrizioni e i prezzi dei prodotti potranno subire modifiche in qualsiasi momento senza alcun preavviso, a nostra esclusiva discrezione. Ci riserviamo il diritto di interrompere in ogni momento la vendita di un qualsiasi prodotto. La vendita di qualsiasi prodotto o servizio tramite questo sito è da considerarsi nulla laddove sia proibita.</p>

            <p>Non garantiamo che la qualità di prodotti, servizi, informazioni o altri materiali da te acquistati o ottenuti soddisfi le tue aspettative, né che gli eventuali errori del Servizio vengano corretti.</p>

            <h2>Sezione 6 - Accuratezza delle informazioni di fatturazione e dell'account</h2>

            <p>Ci riserviamo il diritto di rifiutare qualsiasi ordine ricevuto. A nostra esclusiva discrezione potremo limitare o annullare le quantità acquistate per persona, per nucleo familiare o per ordine. Queste restrizioni potranno riguardare gli ordini effettuati dallo stesso account cliente, con la stessa carta di credito e/o gli ordini che utilizzano lo stesso indirizzo di fatturazione e/o di spedizione. In caso di modifica o annullamento di un ordine, potremo tentare di avvisarti tramite l'indirizzo email, l'indirizzo di fatturazione o il numero di telefono forniti al momento dell'ordine. Ci riserviamo il diritto di limitare o vietare ordini che a nostro insindacabile giudizio sembrino effettuati da grossisti, rivenditori o distributori.</p>

            <p>Accetti di fornire informazioni aggiornate, complete e accurate sull'acquisto e sull'account per tutti gli acquisti effettuati nel nostro sito. Accetti di aggiornare tempestivamente il tuo account e altri dettagli, come l'indirizzo email, il numero e la data di scadenza delle carte di credito, in modo che possiamo completare le transazioni e contattarti se necessario.</p>

            <p>Per maggiori informazioni, consulta la nostra Informativa su rimborsi e resi.</p>

            <h2>Sezione 7 - Strumenti opzionali</h2>
            <p>Potremo fornirti l'accesso a strumenti di terze parti che non monitoriamo e sui quali non abbiamo alcuna forma di controllo o gestione.</p>

            <p>Riconosci e accetti che forniamo l'accesso a tali strumenti ”così come sono” e ”come disponibili”, senza alcun tipo di garanzia, dichiarazione, condizione o avallo. Non avremo alcuna responsabilità derivante da o relativa al tuo utilizzo di strumenti opzionali di terze parti.</p>

            <p>Qualsiasi utilizzo da parte tua degli strumenti opzionali offerti attraverso il sito avverrà interamente a tuo rischio e discrezione. Starà a te assicurarti di conoscere e approvare i termini e le condizioni a cui sono soggetti gli strumenti di terze parti.</p>

            <p>In futuro potremo anche offrire nuovi servizi e/o funzionalità attraverso il sito web (ad esempio, introducendo nuovi strumenti e risorse). Anche tali nuove funzionalità e/o servizi saranno soggetti ai presenti Termini e condizioni del servizio.</p>

            <h2>Sezione 8 - Link di terze parti</h2>

            <p>Alcuni contenuti, prodotti e servizi disponibili tramite il nostro Servizio possono includere materiali di terze parti.</p>

            <p>I link di terze parti su questo sito potranno indirizzarti a siti web esterni, che non sono affiliati con noi. Noi non siamo responsabili di esaminare o valutare i contenuti o l'esattezza di tali siti web. E non forniamo alcuna garanzia né abbiamo alcuna responsabilità per materiali o siti web di terze parti né per altri materiali, prodotti o servizi di terze parti.</p>

            <p>Non risponderemo di eventuali danni connessi all'acquisto o all'utilizzo di beni, servizi, risorse, contenuti o a qualsiasi altra transazione effettuata attraverso siti web di terze parti. Esamina attentamente le policy e le procedure di terze parti e assicurati di averle comprese prima di effettuare qualsiasi transazione. Reclami, richieste, dubbi e domande sui prodotti di terze parti dovranno essere indirizzati ai terzi interessati.</p>

            <h2>Sezione 9 - Commenti, feedback e altri materiali inviati</h2>
            <p>Se ci invii online, via email, tramite posta ordinaria o in altro modo determinati materiali (congiuntamente denominati “commenti”) — ad esempio, su nostra richiesta, contributi per la partecipazione a concorsi, oppure senza una nostra richiesta, idee creative, suggerimenti, proposte, piani o altri materiali — accetti che possiamo in qualsiasi momento e senza limitazioni modificare, copiare, pubblicare, distribuire, tradurre o utilizzare in qualsiasi altro modo e con qualsiasi mezzo i commenti che ci trasmetti. Non abbiamo e non avremo alcun obbligo di (1) mantenere riservati i commenti; (2) pagare compensi per i commenti; o (3) rispondere ai commenti.</p>

            <p>Potremo (senza avere alcun obbligo al riguardo) monitorare, modificare e rimuovere contenuti che dovessimo a nostra esclusiva discrezione ritenere illeciti, offensivi, minacciosi, calunniosi, diffamatori, pornografici, osceni o altrimenti discutibili, o contenuti che violino la proprietà intellettuale di qualsiasi parte o i presenti Termini e condizioni del servizio.</p>

            <p>Accetti di evitare commenti che ledano i diritti di terze parti, tra cui copyright, marchi commerciali, diritto alla privacy, diritti della personalità e altri diritti reali o personali. Inoltre accetti di non inviare commenti che contengano materiale diffamatorio o altrimenti illegale, offensivo o osceno, oppure virus informatici o altri malware che rischiano di compromettere il funzionamento del Servizio o di qualsiasi sito web correlato. Non potrai utilizzare un indirizzo email falso, fingere di essere qualcun altro o altrimenti fuorviare noi o terze parti sull'origine di eventuali commenti. Sei l'unico responsabile dei commenti che invii e della loro accuratezza. Non ci assumiamo e non avremo alcuna responsabilità per eventuali commenti pubblicati da te o da terze parti.</p>

            <h2>Sezione 10 - Informazioni personali</h2>
            <p>L'invio di informazioni personali attraverso il sito è regolato dalla nostra <a target="_blank" href="https://www.iubenda.com/privacy-policy/81740317"
                className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-white"
                title="Privacy Policy">Informativa sulla privacy.</a></p>

            <h2>Sezione 11 - Errori, inesattezze e omissioni</h2>

            <p>Occasionalmente possono essere presenti sul nostro sito o nel Servizio informazioni contenenti errori tipografici, inesattezze e omissioni riguardanti descrizioni dei prodotti, prezzi, promozioni, offerte, costi di spedizione, tempi di consegna o disponibilità dei prodotti. Ci riserviamo il diritto di correggere eventuali errori, inesattezze e omissioni modificando e aggiornando le informazioni o annullando gli ordini, se qualsiasi informazione nel Servizio o su un sito web correlato dovesse essere inaccurata, in ogni momento (anche dopo l'invio dell'ordine) e senza alcun preavviso.</p>

            <p>Non ci assumiamo alcun obbligo di aggiornare, correggere o chiarire le informazioni nel Servizio o in qualsiasi sito web correlato, incluse senza limitazioni le informazioni sui prezzi, salvo quanto previsto dalla legge. Nessuna data di aggiornamento specificata nel Servizio o in qualsiasi sito web correlato dovrà essere interpretata come garanzia che tutte le informazioni nel Servizio o in qualsiasi sito web correlato siano state corrette e aggiornate.</p>



            <h2>Sezione 12 - Usi proibiti</h2>
            <p>Oltre agli altri divieti stabiliti nei Termini e condizioni del servizio, è vietato utilizzare il sito o il suo contenuto:</p>
            <ul>
                <li>per scopi illegali;</li>
                <li>per indurre altri a compiere o partecipare ad atti illeciti;</li>
                <li>per violare leggi e regolamenti internazionali, federali, provinciali o statali, o ordinanze locali;</li>
                <li>per ledere o violare i diritti di proprietà intellettuale nostri o di terzi;</li>
                <li>per molestare, abusare, insultare, danneggiare, diffamare, calunniare, denigrare, intimidire o discriminare qualcuno in base a sesso, orientamento sessuale, religione, etnia, età, paese di origine o disabilità;</li>
                <li>per fornire informazioni false o fuorvianti;</li>
                <li>per caricare o trasmettere virus o qualsiasi altro tipo di codice dannoso idoneo a influire sulla funzionalità o sul funzionamento del Servizio, di qualsiasi sito web correlato, di altri siti web o di internet;</li>
                <li>per raccogliere o monitorare le informazioni personali di altri utenti;</li>
                <li>per spam, phishing, pharming, pretexting, uso di spider, crawling o scraping;</li>
                <li>per qualsiasi scopo osceno o immorale; oppure</li>
                <li>per intralciare o aggirare le funzionalità di sicurezza del Servizio o di qualsiasi sito web correlato, di altri siti web o di internet.</li>
            </ul>
            <p>Ci riserviamo il diritto di interrompere il tuo utilizzo del Servizio o di qualsiasi sito web correlato se violi una qualsiasi delle disposizioni sugli usi proibiti.</p>

            <h2>Sezione 13 - Esclusione di garanzie; limitazione di responsabilità</h2>
            <p>Non garantiamo, affermiamo o dichiariamo che l'utilizzo del nostro servizio sarà ininterrotto, tempestivo, sicuro né privo di errori.</p>

            <p>Non garantiamo che i risultati raggiungibili con l'uso del servizio siano accurati o affidabili.</p>

            <p>Prendi atto che potremo occasionalmente sospendere il servizio per periodi di tempo indeterminati, o cancellare il servizio in qualsiasi momento senza inviarti alcun preavviso.</p>

            <p>Accetti espressamente che siano a tuo esclusivo rischio l'utilizzo e l'impossibilità di utilizzare il servizio. Il servizio e tutti i prodotti e servizi forniti tramite il servizio vengono (salvo quanto da noi espressamente dichiarato) offerti ”così come sono” e ”come disponibili” per l'uso da parte dell'utente, senza dichiarazioni, garanzie o condizioni di alcun tipo, espresse o implicite, tra cui garanzie implicite o condizioni di commerciabilità, qualità commerciabile, idoneità per uno scopo specifico, durata, titolarità e assenza di violazioni.</p>

            <p>In nessun caso Dodaja e i suoi amministratori, dirigenti, dipendenti, affiliati, agenti, appaltatori, stagisti, fornitori, fornitori di servizi o concessori di licenza saranno responsabili per qualsiasi pregiudizio, perdita, reclamo o per un danno diretto, indiretto, incidentale, punitivo, speciale o consequenziale di qualsiasi tipo — inclusi senza limitazione profitti persi, mancati guadagni, risparmi persi, perdita di dati, costi di sostituzione o altri danni simili, in virtù di contratto, illecito civile (anche dovuto a negligenza), responsabilità oggettiva o altro — derivante dall'utilizzo di uno qualsiasi dei servizi o prodotti ottenuti utilizzando il servizio, né per qualsiasi altro reclamo relativo comunque all'utilizzo del servizio o prodotto, compresi, a titolo esemplificativo ma non esaustivo, eventuali errori o omissioni nei contenuti, perdite e danni di qualsiasi genere legati all'uso del servizio o di qualsiasi contenuto (o prodotto) pubblicato, trasmesso o reso altrimenti disponibile attraverso il servizio, anche se informati di tale eventualità.</p>

            <p>Poiché alcuni stati o giurisdizioni non consentono l'esclusione o la limitazione di responsabilità per danni conseguenti o incidentali, in tali stati o giurisdizioni la nostra responsabilità sarà limitata fino al limite massimo consentito dalla legge.</p>

            <h2>Sezione 14 - Indennizzo</h2>

            <p>Accetti di risarcire, difendere e tenere indenne Dodaja e le sue imprese controllanti, controllate e affiliate, nonché i suoi partner, dirigenti, amministratori, agenti, appaltatori, concessori di licenza, fornitori di servizi, subappaltatori, fornitori, stagisti e dipendenti da qualsiasi rivendicazione o richiesta, incluse le spese legali in misura ragionevole, avanzata da qualsiasi soggetto terzo e dovuta o derivante dalla tua violazione dei presenti Termini e condizioni del servizio o dei documenti in essi incorporati mediante riferimento, o dalla tua violazione di qualsiasi legge o diritto di terze parti.</p>

            <h2>Sezione 15 - Separabilità</h2>
            <p>Nel caso in cui una qualsiasi disposizione dei presenti Termini e condizioni del servizio sia ritenuta illegale, nulla o inapplicabile, tale disposizione sarà comunque applicabile nella misura massima consentita dalla legge vigente e la parte inapplicabile sarà considerata scissa dai presenti Termini e condizioni del servizio, senza pregiudizio per la validità e l'applicabilità delle disposizioni rimanenti.</p>

            <h2>Sezione 16 - Risoluzione</h2>
            <p>Gli obblighi e le responsabilità assunti dalle parti prima della data di cessazione sopravvivranno a tutti gli effetti alla risoluzione del presente accordo.</p>

            <p>I presenti Termini e condizioni del servizio saranno efficaci fino al recesso da parte tua o nostra. Potrai risolvere i presenti Termini e condizioni del servizio in qualsiasi momento informandoci che non desideri più utilizzare i nostri Servizi, o interrompendo l'utilizzo del nostro sito.</p>

            <p>Inoltre, se a nostro insindacabile giudizio tu violi o sospettiamo che abbia violato una qualsiasi disposizione dei presenti Termini e condizioni del servizio, potremo risolvere il presente accordo in qualsiasi momento senza preavviso e tu resterai responsabile per tutte le somme dovute fino alla data di cessazione inclusa; di conseguenza potremo negarti l'accesso ai Servizi (o a una qualsiasi parte di essi).</p>

            <h2>Sezione 17 - Intero accordo</h2>
            <p>Il mancato esercizio o applicazione di qualsiasi diritto o disposizione dei presenti Termini e condizioni del servizio non costituirà una rinuncia a tale diritto o disposizione.</p>

            <p>I presenti Termini e condizioni del servizio e le eventuali informative o regole operative pubblicate da noi su questo sito o in relazione al Servizio costituiscono l'intero accordo e intesa tra te e noi e disciplinano l'utilizzo del Servizio da parte tua, sostituendo qualsiasi accordo, comunicazione e proposta precedente o contemporanea, sia orale che scritta, tra te e noi (incluse, senza limitazione, eventuali versioni precedenti dei Termini e condizioni del servizio).</p>

            <p>Eventuali ambiguità nell'interpretazione dei presenti Termini e condizioni del servizio non dovranno essere interpretate contro la parte scrivente.</p>

            <h2>Sezione 18 - Legge applicabile</h2>

            <p>I presenti Termini e condizioni del servizio e gli eventuali accordi separati in base ai quali ti forniamo i Servizi saranno regolati e interpretati in conformità con le leggi vigenti in Largo Luigi Francescon 4 00132 Roma Roma Italia.</p>

            <h2>Sezione 19 - Modifiche ai termini e condizioni del servizio</h2>
            <p>Puoi consultare la versione più aggiornata dei Termini e condizioni del servizio in qualsiasi momento su questa pagina.</p>

            <p>Ci riserviamo il diritto a nostra esclusiva discrezione di aggiornare, modificare o sostituire qualsiasi parte dei presenti Termini e condizioni del servizio pubblicando aggiornamenti e modifiche sul nostro sito web. È tua responsabilità controllare periodicamente la presenza di modifiche nel nostro sito web. La prosecuzione dell'uso o dell'accesso al sito web o al Servizio dopo la pubblicazione di eventuali modifiche ai presenti Termini e condizioni del servizio costituisce accettazione di tali modifiche.</p>

            <h2>Sezione 20 - Contatti</h2>
            <p>Eventuali domande relative ai Termini e condizioni del servizio dovranno esserci inviate all'indirizzo albymoric@gmail.com.</p>

        </div>
    );
};

export default TermsPlain;